//'use strict';
function formatUser(company) {
    return "<p>"+ company.name +"</p>"
}
function formatSelectUser(company) {
    return Select2.util.escapeMarkup(company.name);
}


function formatCompanyEmployee (employee) {
    if (employee.loading) {
        return employee.text;
    }
    var markup = "<div> <table style='width: 100%;'> <td>"+ employee.full_name +"</td></table> </div>"
    return markup;
}
function formatCompanyEmployeeSelection (employee) {
    return employee.name || employee.text;
}

var set_employee_select = function(selector, place_holder){
    if ($(selector).length > 0) {
        $(selector).select2({
            ajax: {
                url: '/api/employees',
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    alert("plplp");
                    return {
                        per_page: 10,
                        q: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data,
                        pagination: {
                            more: (params.page * 10) < data.total_count
                        }
                    };
                },
                cache: true
            },
            minimumInputLength: 1,
            placeholder: place_holder,
            escapeMarkup: function (markup) { return markup; },
            templateResult: formatCompanyEmployee,
            templateSelection: formatCompanyEmployeeSelection
        });
    }
}

export default set_employee_select;