function add_validator_methods() {
    function toNumber(numberStr) {
        return Number(numberStr.replace(",", "."));
    }

    function numberVal(el) {
        return toNumber(el.val());
    }

    // If some element is required, it should be validated even if it's hidden
    $.validator.setDefaults({ ignore: [] });

    //$.validator.setDefaults({ ignore: ":hidden:not(.chosen-select)" });

    $.validator.
    addMethod("accept",
        function(value, element, param) {
            return value.match(new RegExp(/(\.jpe?g|\.gif|\.png|^$)/i));
        }
    );

    $.validator.
    addMethod( "valid_username",
        function(value, element, param) {
            return value.match(new RegExp("(^[A-Za-z0-9_]*$)"));
        }
    );

    $.validator.
    addMethod("regex",
        function(value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }
    );
    $.validator.
    addMethod("email_list",
        function(value, element, param) {
            var emails = value.split(',');
            var re = new RegExp(/^[A-Z0-9._%\-\+\~\/]+@([A-Z0-9-]+\.)+[A-Z]+$/i);
            for (var i = 0; i < emails.length; i++) {
                if (!re.test($.trim(emails[i]))) {
                    return false;
                }
            }
            return true;
        }
    );

    $.validator.
    addMethod("min_date",
        function(value, element, is_rideshare) {
            if (is_rideshare == "true") {
                return get_datetime_from_datetime_select() > new Date();
            } else {
                return get_date_from_date_select() > new Date();
            }
        }
    );

    $.validator.
    addMethod("max_date",
        function(value, element) {
            var current_time = new Date();
            var maximum_date = new Date(new Date(current_time).setMonth(current_time.getMonth()+6));
            return get_date_from_date_select() < maximum_date;
        }
    );

    $.validator.
    addMethod( "positive_integer",
        function(value, element, param) {
            var n = ~~Number(value);
            return String(n) === value && n >= 0;
        }
    );

    $.validator.
    addMethod( "minimum_price_required",
        function(value, element, params) {
            minimum_price = _.first(params) || "";
            subunit_to_unit = _.first(_.rest(params));

            if (minimum_price == "") {
                return true
            } else {
                return minimum_price <= ST.paymentMath.parseSubunitFloatFromFieldValue(value, subunit_to_unit);
            }
        }
    );

    $.validator.
    addMethod( "money",
        function(value, element, minimum_price) {
            var regex = /^\d*((\.|\,)\d{1,2})?$/;
            return value && regex.test(value);
        }
    );

    $.validator.
    addMethod("max_bound",
        function(value, element, otherName) {
            var $otherInput = ST.utils.findElementByName(otherName);
            return Number(toNumber(value)) > numberVal($otherInput);
        }
    );

    $.validator.
    addMethod("min_bound",
        function(value, element, otherName) {
            var $otherInput = ST.utils.findElementByName(otherName);
            return Number(toNumber(value)) < numberVal($otherInput);
        }
    );
    $.validator.
    addMethod("number_conditional_decimals",
        function(value, element, decimalCheckbox) {
            var $otherInput = ST.utils.findElementByName(decimalCheckbox);
            var allowDecimals = $otherInput.is(':checked');
            var numberRegex  = /^\d+?$/;
            var decimalRegex  = /^\d+((\.|\,)\d+)?$/;

            var regexp = allowDecimals ? decimalRegex : numberRegex;

            return regexp.test(value);
        }
    );

    $.validator.addClassRules("required", {
        required: true
    });

    $.validator.addClassRules("number-no-decimals", {
        "number_no_decimals": true
    });

    $.validator.addClassRules("phone-number", {
        minlength: 10,
        maxlength: 15,
        number: true
    });

    $.validator.addClassRules("valid-email", {
        email: true,
        maxlength: 50
    });

    $.validator.addClassRules("number-decimals", {
        "number_decimals": true
    });

    $.validator.addMethod('validUrl', function(value, element) {
        var url = $.validator.methods.url.bind(this);
        return url(value, element) || url('http://' + value, element);
    }, 'Please enter a valid URL');


    // $.validator.addClassRules("url", {
    //   url: true
    // });
    $.validator.addClassRules("url", {
        validUrl: true
    });


    $.validator.
    addMethod("number_no_decimals", function(value, element, opts) {
        var numberRegex  = /^\d+?$/;
        return value.length === 0 ? true : numberRegex.test(value)
    });

    $.validator.
    addMethod("number_decimals", function(value, element) {
        var decimalRegex  = /^\d+((\.|\,)\d+)?$/;
        return value.length === 0 ? true : decimalRegex.test(value)
    });

    $.validator.
    addMethod("number_min", function(value, element, min) {
        return value.length === 0 ? true : toNumber(value) >= min;
    });

    $.validator.
    addMethod("number_max", function(value, element, max) {
        return value.length === 0 ? true : toNumber(value) <= max;
    });

    $.validator.
    addMethod("email_remove_spaces", function(value, element) {
        value = value.trim();
        $(element).val(value);
        return $.validator.methods.email.call(this, value, element)
    });

    $.validator.
    addMethod("noSpace", function(value, element) {
        return value.indexOf(" ") < 0 && value != "";
    }, "No space please and don't leave it empty");

    $.validator.
    addMethod("trimSpace", function(value, element) {
        value = value.trim();
        return value != "";
    }, "don't leave it empty");
}

function initialize_defaults() {
    add_validator_methods();
}
function initialize_signup_form(ele_id){
    var form_id = "#"+ele_id;
    $(".Employer, .Employee").hide();
    $("."+$("input[name='user[type]']:checked").val()).show();
    $('.user_type').on("change", function(){
        $(".Employer, .Employee").hide();
        $("."+$(this).val()).show();
    });
    $(form_id).validate({
        ignore: ":hidden",
        errorPlacement: function(errorLabel, element) {
            if (( /radio|checkbox/i ).test( element[0].type )) {
                element.closest('.checkbox-container').append(errorLabel);
            } else {
                errorLabel.insertAfter( element );
            }
        },
        rules: {
            "user[organization_name]": {required: true},
            "user[first_name]": {required: true},
            "user[last_name]": {required: true},
            "employer[organization_name]": {required: true},
            "user[email]": {required: true, email: true},
            "employer[email]": {required: true, email: true},
            "user[employer_id]": {required: true},
            "user[website_link]": {url: true},
            "user[user_security_questions_attributes][0][security_question_id]": {required: true},
            "user[user_security_questions_attributes][0][question]": {required: true},
            "user[user_security_questions_attributes][0][answer]": {required: true},
            "user[user_security_questions_attributes][1][security_question_id]": {required: true},
            "user[user_security_questions_attributes][1][question]": {required: true},
            "user[user_security_questions_attributes][1][answer]": {required: true},
            "user[password]": { required: true, minlength: 8, pattern: "^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,})$" },
            "user[password_confirmation]": { required: true, minlength: 8, equalTo: "#user_password", noSpace: true },
            "admin[password]": { required: true, minlength: 8, pattern: "^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,})$" },
            "admin[password_confirmation]": { required: true, minlength: 8, equalTo: "#admin_password", noSpace: true },
            "user[tos]": {required: true},
            "user[allow_weekly_payroll]": {required: true},
            "user[ssn]": {required: true, number: true, minlength: 9, maxlength: 9},
            "employee[ssn]": {required: true, number: true, minlength: 9, maxlength: 9},
            "user[address_line_1]": {required: true},
            "user[dob_formatted]": {required: true},
            "user[joining_date_formatted]": {required: true},
            "employee[dob_formatted]": {required: true},
            "employee[joining_date_formatted]": {required: true},
            // "user[address_line_2]": {required: true},
            "user[city]": {required: true},
            "user[state]": {required: true},
            "user[postal_code]": {required: true, number: true, minlength: 5, maxlength: 5},
            "user[contact_email]": {required: true, email: true},
            "user[contact_phone]": {required: true},
            "user[hr_first_name]": {required: true},
            "user[hr_last_name]": {required: true},
            "user[hr_email]": {required: true, email: true},
            "user[hr_phone]": {required: true},
            "user[ceo_first_name]": {required: false},
            "user[ceo_last_name]": {required: false},
            "user[ceo_email]": {required: false, email: true},
            "user[ceo_phone]": {required: false},
            "user[cfo_first_name]": {required: false},
            "user[cfo_last_name]": {required: false},
            "user[cfo_email]": {required: false, email: true},
            "user[cfo_phone]": {required: false},
            "user[pd_first_name]": {required: false},
            "user[pd_last_name]": {required: false},
            "user[pd_email]": {required: false, email: true},
            "user[pd_phone]": {required: false},
            "user[bank_name]": {required: true},
            "user[branch_name]": {required: true},
            "user[bank_id_qualifier]": {required: true},
            "user[bank_id]": {required: true},
            "user[account_type]": {required: true},
            "user[account_number]": {required: true, number: true},
            "user[iban]": {required: true},
            "user[agree_to_pay]": {required: true}
        },
        messages: {
            "user[password]": "Please enter valid password format"
        },
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function(form) {
            if (form_id != "#bank-form"){
                disable_and_submit(form_id, form, "false", locale);
            }
        }
    });
    $(".security-qus").change(function () {
        $(".security-qus").not($(this)).find('option[value='+ $(this).find('option:selected').val() +']').remove();
        if($(this).find('option:selected').text() == "Write my own question"){
            $(this).closest('.user-security-qus').find(".custome-qus").removeClass("d-none").find('input').val('')
        }
        else{
            $(this).closest('.user-security-qus').find(".custome-qus").addClass("d-none")
        }
    })
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id, locale);
    form.submit();
}

function disable_submit_button(form_id) {
    $(form_id).find("button").attr('disabled', 'disabled');
    $(form_id).find("button").text("please_wait");
}

export default initialize_signup_form; initialize_defaults;