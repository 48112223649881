document.addEventListener("turbolinks:load", function () {
    $(window).data('ajaxready', true);
    if($('#all-infinite-scrolling .pagination').length >= 0){
        $(window).data('ajaxready', true).scroll(function() {
            if( $(window).data('ajaxready') == false){
                return
            }
            more_users_url = $('#all-infinite-scrolling  .pagination a[rel=next]').attr('href');
            if(more_users_url && ($(window).scrollTop() + $(window).height() + 2) >= $(document).height()){
                $("#loading").removeClass('hidden');
                $(window).data('ajaxready', false);
                $.getScript(more_users_url);
            }
        });
    }

});


//
// jQuery ->
//
// if $('#all-infinite-scrolling .pagination').length >= 0
//     $(window).data('ajaxready', true).scroll (e) ->
// if $(window).data('ajaxready') == false
//     return
// more_users_url = $('#all-infinite-scrolling  .pagination a[rel=next]').attr('href');
// if more_users_url && $(window).scrollTop() + $(window).height() >= $(document).height()
//     $("#loading").removeClass('hidden')
// $(window).data('ajaxready', false);
// $.getScript more_users_url
// return
// return
//
//
//
// jQuery ->
//   alert("ok")
//   $(window).data('ajaxready', true);
//   if($('#all-infinite-scrolling .pagination').length >= 0)
//     alert("ok1")
//     $(window).data('ajaxready', true).scroll (e) ->
//       alert("ok2")
//       if($(window).data('ajaxready') == false)
//         return
//       more_users_url = $('#all-infinite-scrolling  .pagination a[rel=next]').attr('href');
//       if more_users_url && $(window).scrollTop() + $(window).height() >= $(document).height()
//         $("#loading").removeClass('d-none')
//         $(window).data('ajaxready', false);
//         $.getScript more_users_url
//       return
//     retujQuery(function() {
//         alert("ok");
//         $(window).data('ajaxready', true);
//         if ($('#all-infinite-scrolling .pagination').length >= 0) {
//             alert("ok1");
//             $(window).data('ajaxready', true).scroll(function(e) {
//                 var more_users_url;
//                 alert("ok2");
//                 if ($(window).data('ajaxready') === false) {
//                     return;
//                 }
//                 more_users_url = $('#all-infinite-scrolling  .pagination a[rel=next]').attr('href');
//                 if (more_users_url && $(window).scrollTop() + $(window).height() >= $(document).height()) {
//                     $("#loading").removeClass('d-none');
//                     $(window).data('ajaxready', false);
//                     $.getScript(more_users_url);
//                 }
//             });
//         }
//     });