document.addEventListener("turbolinks:load", function () {
    var start = moment().subtract(30, 'days');
    var end = moment();

    function cb(start, end) {
        $('.reportrange span, .pagereportrange span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
    }

    $('.reportrange, .pagereportrange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(7, 'days'), moment()],
            'Last 30 Days': [moment().subtract(30, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, cb);

    cb(start, end);

    $('.reportrange').on('apply.daterangepicker', function(ev, picker) {
        var startdate = (picker.startDate.format('YYYY-MM-DD'))
        var enddate = (picker.endDate.format('YYYY-MM-DD'))
        var url = $(this).data('url')
        $.ajax({
            url: url,
            type: "POST",
            dataType: "script",
            data:{start_date: startdate, end_date: enddate},
            success:function(response){
            }
        });
    });
    $('.pagereportrange').on('apply.daterangepicker', function(ev, picker) {
        var startdate = (picker.startDate.format('YYYY-MM-DD'))
        var enddate = (picker.endDate.format('YYYY-MM-DD'))
        var url = $(this).data('url')
        $.ajax({
            url: url,
            type: "GET",
            dataType: "script",
            data:{start_date: startdate, end_date: enddate},
            success:function(response){
            }
        });
    });
});

$(document).on("change", "input[name='report_range']", function(){
    if($(this).val() == "Custom date range"){
        $("#custom-daterange").removeClass("d-none")
    } else {
        $("#custom-daterange").addClass("d-none")
    }

});


$(document).on("click", ".top-source-chart-view", function(){
    $("#top-source-chart").removeClass('d-none');
    $("#top-source-table").addClass('d-none');
});

$(document).on("click", ".top-source-table-view", function(){
    $("#top-source-chart").addClass('d-none');
    $("#top-source-table").removeClass('d-none');
});

$(document).on("click", ".user-by-time-chart-view", function(){
    $("#user-by-time-chart").removeClass('d-none');
    $("#user-by-time-table").addClass('d-none');
});

$(document).on("click", ".user-by-time-table-view", function(){
    $("#user-by-time-chart").addClass('d-none');
    $("#user-by-time-table").removeClass('d-none');
});

