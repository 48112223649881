document.addEventListener("turbolinks:load", function () {

    $('#btn-claim-submit').on('click', function(e){
        var currentObj = $(this)
        if(currentObj.closest('form').valid()){
            e.preventDefault();
            start_date = $("#claim_start_date_formatted").val();
            end_date = $("#claim_end_date_formatted").val();

            var start = moment(start_date, "MM-DD-YYYY");
            var end = moment(end_date, "MM-DD-YYYY");
            days = (end.diff(start, "days") + 1)
            $.confirm({
                title: "Are you sure ?",
                content: "You want to claim " + days + " days.",
                type: 'red',
                typeAnimated: true,
                buttons: {
                    "Yes! Sure": function(){
                        currentObj.closest('form').submit();
                    },
                    close: function () {}
                }
            });
        }
    });
});

$(document).on("change", "#donate_day_receiver_id", function(){
    getEmployeeDetails($(this).val())
});

$(document).on("click", "#btn-donation-submit", function(e){
    var currentObj = $(this)
    if(currentObj.closest('form').valid()){
        e.preventDefault();
        days = $("#donate_day_days").val();
        name = $("#emp-user-name").text();

        $.confirm({
            title: "Are you sure ?",
            content: "You are sending " + days + " sick day to " + name,
            type: 'red',
            typeAnimated: true,
            buttons: {
                "GO BACK & EDIT": function () {},
                "CLICK TO SEND": function(){
                    currentObj.closest('form').submit();
                }
            }
        });
    }
});

$(document).on("click", "#btn-donation-request-submit", function(e){
    var currentObj = $(this)
    if(currentObj.closest('form').valid()){
        e.preventDefault();
        days = $("#donate_day_days").val();
        name = $("#emp-user-name").text();

        $.confirm({
            title: "Are you sure ?",
            content: "You are sending request " + days + " sick day to " + name,
            type: 'red',
            typeAnimated: true,
            buttons: {
                "GO BACK & EDIT": function () {},
                "CLICK TO SEND": function(){
                    currentObj.closest('form').submit();
                }
            }
        });
    }
});

$(document).on("change", "#donate_day_sender_id", function(){
    getEmployeeDonateDetails($(this).val())
});

function getEmployeeDetails(emp_id){
    if(emp_id){
        $('#donate_day_days').removeAttr('disabled')
        $('#donate_day_days').closest('.select-days').attr('data-original-title','')
        $.ajax({
            type: 'GET',
            url: '/employees/dashboards/employee_details',
            data: {employee_id: emp_id},
            dataType: "script",
            success: function(result) {}
        });
    } else {
        $('#donate_day_days').attr('disabled', true)
        $('#donate_day_days').closest('.select-days').attr('data-original-title','First Select Employee')
        $("#employeeDetails").html("")
    }
}

function getEmployeeDonateDetails(emp_id){
    if(emp_id){
        $.ajax({
            type: 'GET',
            url: '/employees/dashboards/employee_detail_donates',
            data: {employee_id: emp_id},
            dataType: "script",
            success: function(result) {}
        });
    } else {
        $("#employeeDetails").html("")
    }
}