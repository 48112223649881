(function ($) {
  document.addEventListener("turbolinks:load", function () {

    var chart,
      chartClass = '.js-area-chart-small',
      data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        datasets: [{
          backgroundColor: 'transparent'
        }]
      },
      options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        elements: {
          line: {
            borderWidth: 2
          },
          point: {
            radius: 0
          }
        },
        layout: {
          padding: {
            top: 1,
            bottom: 1
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              display: false,
              padding: 0
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              min: 0,
              max: 100,
              display: false,
              padding: 0
            }
          }]
        }
      };

    $(chartClass).each(function (i, el) {

      var extendedData = JSON.parse(el.getAttribute('data-extend'));

      for(var ii = 0; ii < data.datasets.length; ii++) {
        data.datasets[ii].data = extendedData[ii].data;
        data.datasets[ii].borderColor = extendedData[ii].borderColor;
      }

      if($(el).parents().is('.tab-pane')) {

        if($(el).parents().is('.tab-pane.active')) {

          chart = new Chart(el, {
            type: 'line',
            data: data,
            options: options
          });

          $(el).addClass('initialized');

        }

      } else {

        chart = new Chart(el, {
          type: 'line',
          data: data,
          options: options
        });

        $(el).addClass('initialized');

      }

    });

    if ($('[data-toggle="tab"]').length) {

      $('[data-toggle="tab"]').on('shown.bs.tab', function (e) {

        var $targetEl = $($(e.target).attr('href')).find(chartClass + ':not(.initialized)');

        if ($targetEl.length) {

          chart = new Chart($targetEl, {
            type: 'line',
            data: data,
            options: options
          });

          $targetEl.addClass('initialized');

        }

      });

    }

  });
})(jQuery);

export function loadMembershipLineChart(emp_full_array, emp_associate_array, emp_incomplete_array, cmp_full_array, cmp_associate_array, cmp_incomplete_array){
    var MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var emp_data = {
        labels: MONTHS,
        datasets: [{
            label: 'Full Membership',
            borderColor: 'rgba(20, 10, 130, 1)',
            backgroundColor: 'rgba(20, 10, 130, .1)',
            data: emp_full_array,
            fill: false,
        }, {
            label: 'Associate Membership',
            fill: false,
            borderColor: 'rgba(18, 175, 226, 1)',
            backgroundColor: 'rgba(18, 175, 226, .1)',
            data: emp_associate_array,
        }, {
            label: 'Incomplete Profile',
            fill: false,
            borderColor: 'rgba(153,155,168, 1)',
            backgroundColor: 'rgba(153,155,168, .1)',
            data: emp_incomplete_array,
        }
        ]
    }
    var cmp_data = {
        labels: MONTHS,
        datasets: [{
            label: 'Full Membership',
            borderColor: 'rgba(20, 10, 130, 1)',
            backgroundColor: 'rgba(20, 10, 130, .1)',
            data: cmp_full_array,
            fill: false,
        }, {
            label: 'Associate Membership',
            fill: false,
            borderColor: 'rgba(18, 175, 226, 1)',
            backgroundColor: 'rgba(18, 175, 226, .1)',
            data: cmp_associate_array,
        }, {
            label: 'Incomplete Profile',
            fill: false,
            borderColor: 'rgba(153,155,168, 1)',
            backgroundColor: 'rgba(153,155,168, .1)',
            data: cmp_incomplete_array,
        }]
    }

    var options =  {
        responsive: true,
            title: {
            display: true
        },
        // tooltips: {
        //     mode: 'index',
        //     intersect: false,
        // },
        tooltips: {
            enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0];

                        innerHtml +=  oldBody;
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        hover: {
            mode: 'nearest',
                intersect: true
        },
        legend: {
            display: false
        },
        // scales: {
        //     yAxes: [{
        //         display: false,
        //         ticks: {
        //             beginAtZero: true,
        //             min: 0,
        //             suggestedMin: 0,
        //             callback: function(value) { return value },
        //         }
        //     }]
        // },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: ''
                }
            }],
                yAxes: [{
                display: true,
                // scaleLabel: {
                //     display: true,
                //     labelString: ''
                // },
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        }
    }

    var emp_config = {
        type: 'line',
        data: emp_data,
        options: options
    };

    var cmp_config = {
        type: 'line',
        data: cmp_data,
        options: options
    };

    var emp_ctx = document.getElementById('canvasEmployee').getContext('2d');
    window.myLine = new Chart(emp_ctx, emp_config);

    var cmp_ctx = document.getElementById('canvasEmployer').getContext('2d');
    window.myLine = new Chart(cmp_ctx, cmp_config);
}
// export function loadEmpPaidLineChart(array){
//     var MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     var config = {
//         type: 'line',
//         data: {
//             labels: MONTHS,
//             datasets: [{
//                 label: 'Monthly Paid',
//                 backgroundColor: '#140A82',
//                 borderColor: '#140A82',
//                 data: array,
//                 fill: false,
//             }]
//         },
//         options: {
//             responsive: true,
//             title: {
//                 display: false
//             },
//             tooltips: {
//                 mode: 'index',
//                 intersect: false,
//             },
//             hover: {
//                 mode: 'nearest',
//                 intersect: true
//             },
//             scales: {
//                 xAxes: [{
//                     display: true,
//                     scaleLabel: {
//                         display: true,
//                         labelString: ''
//                     }
//                 }],
//                 yAxes: [{
//                     display: true,
//                     scaleLabel: {
//                         display: true,
//                         labelString: ''
//                     }
//                 }]
//             }
//         }
//     };
//
//     var ctx = document.getElementById('canvas').getContext('2d');
//     window.myLine = new Chart(ctx, config);
// }


export function loadEmpPaidLineChart(array){
    var chart,
        chartClass = '.emp-paid-chart',

        data = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                data: array,

                borderColor: 'rgba(18, 175, 226, 1)',
                backgroundColor: 'rgba(18, 175, 226, .1)',

                pointBackgroundColor: "#ffffff",
                pointShadowColor: 'rgba(0, 0, 0, .19)',
                pointShadowOffsetX: 0,
                pointShadowOffsetY: 2
            }]
        },
        options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        suggestedMin: 0,
                        callback: function(value) { return value },
                    }
                }]
            },
            hover: {
                mode: 'nearest',
                intersect: false
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjsAreaTooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjsAreaTooltip';
                        tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                        tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [],
                            bodyLines = tooltipModel.body.map(getBody),
                            today = new Date();

                        var innerHtml = '<header class="u-chartjs-tooltip-header">';

                        titleLines.forEach(function (title) {
                            innerHtml += title + ', ' + today.getFullYear();
                        });

                        innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                        bodyLines.forEach(function (body, i) {
                            var oldBody = body[0],
                                newBody = oldBody.substring(0, oldBody.length - 3) + oldBody.substring(oldBody.length - 3);

                            innerHtml +=  (oldBody.length > 3 ? newBody : body);
                        });

                        innerHtml += '</div>';

                        var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                        tooltipRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    // console.log();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                },
                point: {
                    pointStyle: 'circle',
                    radius: 5,
                    hoverRadius: 7,
                    borderWidth: 3,
                    backgroundColor: '#ffffff'
                }
                // },
                // scales: {
                //     xAxes: [{
                //         gridLines: {
                //             display: false,
                //             drawBorder: false
                //         },
                //         ticks: {
                //             fontWeight: 400,
                //             fontSize: 14,
                //             fontFamily: 'Roboto, sans-serif',
                //             fontColor: '#999BA8'
                //         }
                //     }],
                //     yAxes: [{
                //         gridLines: {
                //             borderDash: [8, 8],
                //             color: '#eaf2f9',
                //             drawBorder: false,
                //             drawTicks: false,
                //             zeroLineColor: 'transparent'
                //         },
                //         ticks: {
                //             min: 0,
                //             max: 600,
                //             display: false,
                //             padding: 0
                //         }
                //     }]
            }
        };

    $(chartClass).each(function (i, el) {

        chart = new Chart(el, {
            type: 'line',
            data: data,
            options: options
        });

        $(el).addClass('initialized');


    });


}
export function loadCmpPaidLineChart(array){
    var chart,
        chartClass = '.cmp-paid-chart',

        data = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                data: array,

                borderColor: 'rgba(20, 10, 130, 1)',
                backgroundColor: 'rgba(20, 10, 130, .1)',
                pointBackgroundColor: "#ffffff",
                pointShadowColor: 'rgba(0, 0, 0, .19)',
                pointShadowOffsetX: 0,
                pointShadowOffsetY: 2
            }]
        },
        options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        suggestedMin: 0,
                        callback: function(value) { return value },
                    }
                }]
            },
            hover: {
                mode: 'nearest',
                intersect: false
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjsAreaTooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjsAreaTooltip';
                        tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                        tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [],
                            bodyLines = tooltipModel.body.map(getBody),
                            today = new Date();

                        var innerHtml = '<header class="u-chartjs-tooltip-header">';

                        titleLines.forEach(function (title) {
                            innerHtml += title + ', ' + today.getFullYear();
                        });

                        innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                        bodyLines.forEach(function (body, i) {
                            var oldBody = body[0],
                                newBody = oldBody.substring(0, oldBody.length - 3) + oldBody.substring(oldBody.length - 3);

                            innerHtml +=  (oldBody.length > 3 ? newBody : body);
                        });

                        innerHtml += '</div>';

                        var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                        tooltipRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    // console.log();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                },
                point: {
                    pointStyle: 'circle',
                    radius: 5,
                    hoverRadius: 7,
                    borderWidth: 3,
                    backgroundColor: '#ffffff'
                }
            // },
            // scales: {
            //     xAxes: [{
            //         gridLines: {
            //             display: false,
            //             drawBorder: false
            //         },
            //         ticks: {
            //             fontWeight: 400,
            //             fontSize: 14,
            //             fontFamily: 'Roboto, sans-serif',
            //             fontColor: '#999BA8'
            //         }
            //     }],
            //     yAxes: [{
            //         gridLines: {
            //             borderDash: [8, 8],
            //             color: '#eaf2f9',
            //             drawBorder: false,
            //             drawTicks: false,
            //             zeroLineColor: 'transparent'
            //         },
            //         ticks: {
            //             min: 0,
            //             max: 600,
            //             display: false,
            //             padding: 0
            //         }
            //     }]
            }
        };

    $(chartClass).each(function (i, el) {

            chart = new Chart(el, {
                type: 'line',
                data: data,
                options: options
            });

            $(el).addClass('initialized');


    });
}

export function loadAdminAccountChart(cash_in_arr, cash_out_arr, ending_bal_arr, tranc_cash_in_arr, tranc_cash_out_arr){
    var chart,
        chartClass = '.barChartCashInCashOut',

        barData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                borderColor: 'rgba(20, 10, 130, 1)',
                label: 'Cash In',
                backgroundColor: 'rgba(20, 10, 130, 1)',
                data: cash_in_arr,
                fill: false,
            }, {
                label: 'Cash Out',
                fill: false,
                borderColor: 'rgba(18, 175, 226, 1)',
                backgroundColor: 'rgba(18, 175, 226, 1)',
                data: cash_out_arr,
            }
            ]
        },
        options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        suggestedMin: 0,
                        callback: function(value) { return value },
                    }
                }]
            },
            hover: {
                mode: 'nearest',
                intersect: false
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjsAreaTooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjsAreaTooltip';
                        tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                        tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [],
                            bodyLines = tooltipModel.body.map(getBody),
                            today = new Date();

                        var innerHtml = '<header class="u-chartjs-tooltip-header">';

                        titleLines.forEach(function (title) {
                            innerHtml += title + ', ' + today.getFullYear();
                        });

                        innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                        bodyLines.forEach(function (body, i) {
                            var oldBody = body[0],
                                ltext = oldBody.split(": ")[0],
                                amount = numberToHuman(oldBody.split(": ")[1]),
                                // parseamount = parseFloat(parseInt(amount) === amount ? amount+".00" : amount).toFixed(2),
                                // newBody = parseamount.length > 6 ? ltext + ': $' + parseamount.substring(0, parseamount.length - 6) + ',' + parseamount.substring(parseamount.length - 6) : ltext + ': $' + parseamount;
                                newBody = ltext + ': $' + amount
                            innerHtml += newBody;
                        });

                        innerHtml += '</div>';

                        var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                        tooltipRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    // console.log();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                },
                point: {
                    pointStyle: 'circle',
                    radius: 5,
                    hoverRadius: 7,
                    borderWidth: 3,
                    backgroundColor: '#ffffff'
                }
                // },
                // scales: {
                //     xAxes: [{
                //         gridLines: {
                //             display: false,
                //             drawBorder: false
                //         },
                //         ticks: {
                //             fontWeight: 400,
                //             fontSize: 14,
                //             fontFamily: 'Roboto, sans-serif',
                //             fontColor: '#999BA8'
                //         }
                //     }],
                //     yAxes: [{
                //         gridLines: {
                //             borderDash: [8, 8],
                //             color: '#eaf2f9',
                //             drawBorder: false,
                //             drawTicks: false,
                //             zeroLineColor: 'transparent'
                //         },
                //         ticks: {
                //             min: 0,
                //             max: 600,
                //             display: false,
                //             padding: 0
                //         }
                //     }]
            }
        };

    $(chartClass).each(function (i, el) {

        chart = new Chart(el, {
            type: 'bar',
            data: barData,
            options: options
        });

        $(el).addClass('initialized');

    });


    var lineData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
            borderColor: 'rgba(20, 10, 130, 1)',
            label: 'Balance',
            backgroundColor: 'rgba(20, 10, 130, .1)',
            data: ending_bal_arr,
            fill: false,
        }
        ]
    };

    $('.lineChartEndingBalance').each(function (i, el) {
        new Chart(el, {
            type: 'line',
            data: lineData,
            options: options
        });

        $(el).addClass('initialized');

    });


    // var data2 = {
    //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //     datasets: [{
    //         backgroundColor: ["#5ebab1", "#00b2d6", "#ffbd4b", "#0096e6", "#ff7f4d", "#f23e64", "#f23e64", "#0076e3", "#c0007e", "#8a6795", "#004fc7", "#6e0b93"] ,
    //         hoverBackgroundColor: ["#5ebab1", "#00b2d6", "#ffbd4b", "#0096e6", "#ff7f4d", "#f23e64", "#f23e64", "#0076e3", "#c0007e", "#8a6795", "#004fc7", "#6e0b93"] ,
    //         label: 'Cash In',
    //         data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
    //         fill: false,
    //     }, {
    //         label: 'Cash Out',
    //         fill: false,
    //         backgroundColor: ["#6e0b93", "#004fc7",  "#8a6795",  "#c0007e",  "#0076e3",  "#f23e64",  "#f23e64",  "#ff7f4d",  "#0096e6",  "#ffbd4b",  "#00b2d6",  "#5ebab1"],
    //         hoverBackgroundColor: ["#6e0b93", "#004fc7",  "#8a6795",  "#c0007e",  "#0076e3",  "#f23e64",  "#f23e64",  "#ff7f4d",  "#0096e6",  "#ffbd4b",  "#00b2d6",  "#5ebab1"],
    //         data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
    //     }
    //     ]
    // };

    var TracCashInData = {
        labels: ['Annual Deposit', 'Banked Days', 'Donated Days', 'Enrollments', 'Weekly'],
        datasets: [{
            backgroundColor: getRandomColorEach(5),
            hoverBackgroundColor: getRandomColorEach(5),
            label: 'Cash In',
            data: tranc_cash_in_arr,
            fill: false,
        }
        ]
    };


    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            ltext = oldBody.split(": ")[0],
                            amount = numberToHuman(oldBody.split(": ")[1]),
                            // parseamount = parseFloat(parseInt(amount) === amount ? amount+".00" : amount).toFixed(2),
                            // newBody = parseamount.length > 6 ? ltext + ': $' + parseamount.substring(0, parseamount.length - 6) + ',' + parseamount.substring(parseamount.length - 6) : ltext + ': $' + parseamount;
                            newBody = ltext + ': $' + amount
                            // amount = oldBody.split(": ")[1],
                            // parseamount = parseFloat(parseInt(amount) === amount ? amount+".00" : amount).toFixed(2),
                            // newBody = parseamount.length > 6 ? ltext + ': $' + parseamount.substring(0, parseamount.length - 6) + ',' + parseamount.substring(parseamount.length - 6) : ltext + ': $' + parseamount;
                        innerHtml +=  newBody;
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var options2 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        innerHtml += '$0';
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };

    $('.pieChartCashIn').each(function (i, el) {
        new Chart(el, {
            type: 'doughnut',
            data: TracCashInData,
            options: options1
        });

        $(el).addClass('initialized');

    });


        if (tranc_cash_out_arr[0] < 1 && tranc_cash_out_arr[0] < 1){
            var TracCashOutData =   {
                labels: [''],
                dataLabels: {
                    formatter: function() {
                        if (this.y > 0) {
                            return this.point.name + ': ' + 0 + ' %'
                        }
                    }
                },
                datasets: [{
                    backgroundColor: ["#999ba8"],
                    hoverBackgroundColor: ["#999ba8"],
                    label: '',
                    data: [1],
                    fill: false,
                }
                ]
            };

            $('.pieChartCashOut').each(function (i, el) {
                new Chart(el, {
                    type: 'doughnut',
                    data: TracCashOutData,
                    options: options2
                });

                $(el).addClass('initialized');

            });
        } else {
            var TracCashOutData =   {
                labels: ['Sick Day Claims', 'FMLA Claims'],
                datasets: [{
                    backgroundColor: ["#140A82", "#12AFE2"],
                    hoverBackgroundColor: ["#140A82", "#12AFE2"],
                    label: 'Cash In',
                    data: tranc_cash_out_arr,
                    fill: false,
                }
                ]
            };
            $('.pieChartCashOut').each(function (i, el) {
                new Chart(el, {
                    type: 'doughnut',
                    data: TracCashOutData,
                    options: options1
                });

                $(el).addClass('initialized');

            });
        }




}

var options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true
    },
    scales: {
        yAxes: [{
            display: true,
            ticks: {
                beginAtZero: true,
                min: 0,
                suggestedMin: 0,
                callback: function(value) { return value },
            }
        }]
    },
    hover: {
        mode: 'nearest',
        intersect: false
    },
    tooltips: {
        enabled: false,
        mode: 'nearest',
        intersect: false,
        custom: function (tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjsAreaTooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjsAreaTooltip';
                tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [],
                    bodyLines = tooltipModel.body.map(getBody),
                    today = new Date();

                var innerHtml = '<header class="u-chartjs-tooltip-header">';

                titleLines.forEach(function (title) {
                    innerHtml += title
                });

                innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                bodyLines.forEach(function (body, i) {
                    var oldBody = body[0],

                        newBody = oldBody
                    innerHtml +=  newBody
                });

                innerHtml += '</div>';

                var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                tooltipRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            // console.log();

            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
            tooltipEl.style.pointerEvents = 'none';
        }
    },
    elements: {
        line: {
            borderWidth: 3
        },
        point: {
            pointStyle: 'circle',
            radius: 5,
            hoverRadius: 7,
            borderWidth: 3,
            backgroundColor: '#ffffff'
        }
    }
}
export function loadAdminHelpAvgCharts(avg_tickets, avg_cbr, avg_cl, avg_eq){
    var ticketData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                label: "Tickets",
                data: avg_tickets,
                backgroundColor: 'rgba(241,37,89, .1)',
                borderColor: 'rgba(241,37,89, 1)'
            }
            ]
        },
        cbrData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                label: "Call Back Requests",
                data: avg_cbr,
                backgroundColor: 'rgba(0,201,167, .1)',
                borderColor: 'rgba(0,201,167, 1)'
            }
            ]
        },
        clData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                label: "Call Logs",
                data: avg_cl,
                backgroundColor: 'rgba(44,210,246, .1)',
                borderColor: 'rgba(44,210,246, 1)'
            }
            ]
        },
        eqData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                label: "Email Queries",
                data: avg_eq,
                backgroundColor: 'rgba(241,190,37, .1)',
                borderColor: 'rgba(241,190,37, 1)'
            }
            ]
        }
    $('.lineChartHelpTicketAvg').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'line',
            data: ticketData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
 $('.lineChartHelpCBRAvg').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'line',
            data: cbrData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
 $('.lineChartHelpCLAvg').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'line',
            data: clData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
 $('.lineChartHelpEQAvg').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'line',
            data: eqData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
}
export function loadAdminHelpAvgChart(helps_avg){
    var barData = {
            labels: ['Tickets', 'Call Back Requests', 'Call Logs', 'Live Chats', 'Email Queries'],
            datasets: [{
                label: "Average Resolved Time In Minutes",
                data: helps_avg,
                backgroundColor: getRandomColorEach(5),
                borderColor: getRandomColorEach(5)
            }
            ]
        }
    $('.lineChartHelpAvg').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'bar',
            data: barData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });

}
export function loadAdminHelpChart(help_tickets, call_backs, call_logs, live_chats, email_queries, total_helps){
    var chart,
        chartClass = '.lineChartHelps',

        lineData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                borderColor: '#F12559',
                label: 'Help Tickets',
                backgroundColor: '#F12559',
                data: help_tickets,
                fill: false,
            }, {
                label: 'Call Backs',
                fill: false,
                borderColor: '#00C9A7',
                backgroundColor: '#00C9A7',
                data: call_backs,
            },
            {
                borderColor: '#2CD2F6',
                label: 'Call Logs',
                backgroundColor: '#2CD2F6',
                data: call_logs,
                fill: false,
            }, {
                label: 'Live Chats',
                fill: false,
                borderColor: '#444BF8',
                backgroundColor: '#444BF8',
                data: live_chats,
            }, {
                label: 'Email Queries',
                fill: false,
                borderColor: '#F1BE25',
                backgroundColor: '#F1BE25',
                data: email_queries,
            }
            ]
        };

    $(chartClass).each(function (i, el) {

        chart = new Chart(el, {
            type: 'line',
            data: lineData,
            options: options
        });

        $(el).addClass('initialized');

    });


    var totalHelpsData = {
        labels: ['Help Tickets', 'Call Backs', 'Call Logs', 'Live Chats', 'Email Queries'],
        datasets: [{
            backgroundColor: getRandomColorEach(5),
            hoverBackgroundColor: getRandomColorEach(5),
            label: 'Helps',
            data: total_helps,
            fill: false,
        }
        ]
    };


    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0]
                        innerHtml +=  oldBody;
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };


    $('.pieChartHelpDesk').each(function (i, el) {
        new Chart(el, {
            type: 'doughnut',
            data: totalHelpsData,
            options: options1
        });

        $(el).addClass('initialized');

    });
}
export function loadAdminHelpTicketAnalyticCharts(date_serise, avg_response, avg_resolution, no_priority, low_priority, mid_priority, high_priority, no_priority_avg, low_priority_avg, mid_priority_avg, high_priority_avg){
    var chart,
        chartClass = '.lineChartAvgResponseAndResolution',

        lineData = {
            labels: date_serise,
            datasets: [{
                    borderColor: '#F12559',
                    label: 'Average Response',
                    backgroundColor: '#F12559',
                    data: avg_response,
                    fill: false,
                }, {
                    label: 'Average Resolution',
                    fill: false,
                    borderColor: '#00C9A7',
                    backgroundColor: '#00C9A7',
                    data: avg_resolution,
                }
            ]
        };
    if (no_priority){
        var barData = {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    borderColor: '#F12559',
                    label: 'No Priority',
                    backgroundColor: '#F12559',
                    data: no_priority,
                    fill: false
                }, {
                    label: 'Low Priority',
                    fill: false,
                    borderColor: '#00C9A7',
                    backgroundColor: '#00C9A7',
                    data: low_priority
                },
                    {
                        borderColor: '#444BF8',
                        label: 'Mid Priority',
                        backgroundColor: '#444BF8',
                        data: mid_priority,
                        fill: false
                    },
                    {
                        label: 'High Priority',
                        fill: false,
                        borderColor: '#F1BE25',
                        backgroundColor: '#F1BE25',
                        data: high_priority
                    }
                ]
            },
            barDataAvg = {
                labels: ['Average Response', 'Average Resolution'],
                datasets: [{
                    borderColor: '#F12559',
                    label: 'No Priority',
                    backgroundColor: '#F12559',
                    data: no_priority_avg,
                    fill: false
                }, {
                    label: 'Low Priority',
                    fill: false,
                    borderColor: '#00C9A7',
                    backgroundColor: '#00C9A7',
                    data: low_priority_avg
                },
                    {
                        borderColor: '#444BF8',
                        label: 'Mid Priority',
                        backgroundColor: '#444BF8',
                        data: mid_priority_avg,
                        fill: false
                    },
                    {
                        label: 'High Priority',
                        fill: false,
                        borderColor: '#F1BE25',
                        backgroundColor: '#F1BE25',
                        data: high_priority_avg
                    }
                ]
            }
        ;
        $('.barChartTicketPriority').each(function (i, el) {

            chart = new Chart(el, {
                type: 'bar',
                data: barData,
                options: options
            });

            $(el).addClass('initialized');

        });
        $('.barChartTicketPriorityByResAndResolution').each(function (i, el) {

            chart = new Chart(el, {
                type: 'bar',
                data: barDataAvg,
                options: options
            });

            $(el).addClass('initialized');

        });
    }


    $(chartClass).each(function (i, el) {

        chart = new Chart(el, {
            type: 'line',
            data: lineData,
            options: options
        });

        $(el).addClass('initialized');

    });


}
export function loadAdminHelpCBRAnalyticCharts(date_serise, avg_response, avg_resolution){
    var chart,
        chartClass = '.lineChartAvgResponseAndResolution',

        lineData = {
            labels: date_serise,
            datasets: [{
                    borderColor: '#F12559',
                    label: 'Average Response',
                    backgroundColor: '#F12559',
                    data: avg_response,
                    fill: false,
                }, {
                    label: 'Average Resolution',
                    fill: false,
                    borderColor: '#00C9A7',
                    backgroundColor: '#00C9A7',
                    data: avg_resolution,
                }
            ]
        };

    $(chartClass).each(function (i, el) {

        chart = new Chart(el, {
            type: 'line',
            data: lineData,
            options: options
        });

        $(el).addClass('initialized');

    });
}
export function loadAdminHelpEQAnalyticCharts(date_serise, avg_response, avg_resolution){
    var chart,
        chartClass = '.lineChartAvgResponseAndResolution',

        lineData = {
            labels: date_serise,
            datasets: [{
                    borderColor: '#F12559',
                    label: 'Average Response',
                    backgroundColor: '#F12559',
                    data: avg_response,
                    fill: false,
                }, {
                    label: 'Average Resolution',
                    fill: false,
                    borderColor: '#00C9A7',
                    backgroundColor: '#00C9A7',
                    data: avg_resolution,
                }
            ]
        };

    $(chartClass).each(function (i, el) {

        chart = new Chart(el, {
            type: 'line',
            data: lineData,
            options: options
        });

        $(el).addClass('initialized');

    });
}



export function loadAdminAnalyticChart(source_keys, source_count){
    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        // legendCallback: function(chart) {
        //     var text = [];
        //     var legs = [];
        //     for( var j=0; j<chart.data.datasets.length;j++)
        //     {
        //         for (var i = 0; i < chart.data.datasets[j].data.length; i++)
        //         {
        //             var newd = { label: chart.data.datasets[j].labels[i] , color: chart.data.datasets[j].backgroundColor[i]  };
        //
        //             if( !containsObject (newd,legs) )
        //             {
        //                 legs.push(newd);
        //             }
        //         }
        //     }
        //
        //     text.push('<div class=" row ' + chart.id + '-legend">');
        //     for( var k =0;k<legs.length;k++)
        //     {
        //         text.push('<div class="col-md-6 p-0"><div class="m-1 align-items-center shadow rounded-pill p-2 pr-3">' +
        //             '<span class="rounded-pill px-4 mr-2" style="background-color:' + legs[k].color + '"></span>');
        //         text.push(legs[k].label);
        //         text.push('</div></div>');
        //     }
        //     text.push('</div>');
        //     return text.join("");
        // },
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    try {
                        let label = ' ' + data.labels[tooltipItem.index] || '';

                        if (label) {
                            label += ': ';
                        }

                        const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                            return parseInt(accumulator) + parseInt(curValue);
                        });
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        label += value
                        label += " ("+Number((value / sum) * 100).toFixed(2) + '%'+")";
                        return label;
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var SourceCountData = {
        labels: source_keys,
        datasets: [{
            labels: source_keys,
            data: source_count,
            backgroundColor: getRandomColorEach(source_count.length)
        }
        ]
    };
    $('.pieChartSource').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'doughnut',
            data: SourceCountData,
            options: options1
        });
        $(el).addClass('initialized');
        // $("#source-chartjs-legend").html(myChart.generateLegend());
    });
}
export function loadAdminAnalyticEmpMemberChart(emp_keys, emp_count){
    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    try {
                        let label = ' ' + data.labels[tooltipItem.index] || '';

                        if (label) {
                            label += ': ';
                        }

                        const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                            return parseInt(accumulator) + parseInt(curValue);
                        });
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        label += value
                        label += " ("+Number((value / sum) * 100).toFixed(2) + '%'+")";
                        return label;
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var options2 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        innerHtml += '0';
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };

    var EmpCountData = {
        labels: emp_keys,
        datasets: [{
            label: 'Employee Membership',
            data: emp_count,
            backgroundColor: getRandomColorEach(emp_count.length)
        }
        ]
    };
    if (emp_count[0] < 1 && emp_count[1] < 1) {
        var EmpCountData = {
            labels: [''],
            dataLabels: {
                formatter: function () {
                    if (this.y > 0) {
                        return this.point.name + ': ' + 0 + ' %'
                    }
                }
            },
            datasets: [{
                backgroundColor: ["#999ba8"],
                hoverBackgroundColor: ["#999ba8"],
                label: '',
                data: [1],
                fill: false,
            }
            ]
        };
    }
    $('.pieChartEmp').each(function (i, el) {
        new Chart(el, {
            type: 'doughnut',
            data: EmpCountData,
            options: emp_count[0] < 1 && emp_count[1]< 1 ? options2 : options1
        });

        $(el).addClass('initialized');

    });
}
export function loadAdminAnalyticCompMemberChart(comp_keys, comp_values){

    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    try {
                        let label = ' ' + data.labels[tooltipItem.index] || '';

                        if (label) {
                            label += ': ';
                        }

                        const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                            return parseInt(accumulator) + parseInt(curValue);
                        });
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        label += value
                        label += " ("+Number((value / sum) * 100).toFixed(2) + '%'+")";
                        return label;
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var options2 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        innerHtml += '0';
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var CompCountData = {
        labels: comp_keys,
        datasets: [{
            label: 'Employer Membership',
            data: comp_values,
            backgroundColor: getRandomColorEach(comp_values.length)
        }
        ]
    };
    if (comp_values[0] < 1 && comp_values[1] < 1) {
        var CompCountData = {
            labels: [''],
            dataLabels: {
                formatter: function () {
                    if (this.y > 0) {
                        return this.point.name + ': ' + 0 + ' %'
                    }
                }
            },
            datasets: [{
                backgroundColor: ["#999ba8"],
                hoverBackgroundColor: ["#999ba8"],
                label: '',
                data: [1],
                fill: false,
            }
            ]
        };
    }
    $('.pieChartComp').each(function (i, el) {
        new Chart(el, {
            type: 'doughnut',
            data: CompCountData,
            options: comp_values[0] < 1 && comp_values[1] < 1 ? options2 : options1
        });

        $(el).addClass('initialized');

    });

}
export function loadAdminAnalyticEmpInvChart(invitation_count, total_invitation_count){

    var options3 = {
        responsive: true,
        maintainAspectRatio: false,
        legendCallback: function(chart) {
            var text = [];
            var legs = [];
            for( var j=0; j<chart.data.datasets.length;j++)
            {
                for (var i = 0; i < chart.data.datasets[j].data.length; i++)
                {
                    var newd = { label: chart.data.datasets[j].labels[i] , color: chart.data.datasets[j].backgroundColor[i]  };

                    if( !containsObject (newd,legs) )
                    {
                        legs.push(newd);
                    }
                }
            }

            text.push('<ul class="Mylegend ' + chart.id + '-legend">');
            for( var k =0;k<legs.length;k++)
            {
                text.push('<li><div class="align-items-center"><span style="background-color:' + legs[k].color + '"></span>');
                text.push(legs[k].label);
                text.push('</div></li>');
            }
            text.push('</ul>');
            return text.join("");
        },
        legend: {
            display: false,
            position: 'bottom'
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var index = tooltipItem.index;
                    return dataset.labels[index] + ': ' + dataset.data[index];
                }
            },
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var options2 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        innerHtml += '0';
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var InvitationCountData = {
        labels: ["Joined Bulk Invitations", "Outstanding Bulk Invitations", "Joined Individual Invitations", "Outstanding Individual Invitations"],
        datasets: [{
            labels: ["Joined Bulk Invitations", "Outstanding Bulk Invitations", "Joined Individual Invitations", "Outstanding Individual Invitations"],
            data: invitation_count,
            backgroundColor: ["#444BF8", "#DD69D1", "#00ED96", "#F1BE25"]
        },{
            labels: ["Total Bulk Invitations Sent", "Total Individual Invitations Sent"],
            data: total_invitation_count,
            backgroundColor: ["#140A82", "#2CD2F6"]
        }
        ]
    };
    if (invitation_count[0] < 1 && invitation_count[1] < 1 && invitation_count[2] < 1 && invitation_count[3] < 1) {
        var InvitationCountData = {
            labels: [''],
            dataLabels: {
                formatter: function () {
                    if (this.y > 0) {
                        return this.point.name + ': ' + 0 + ' %'
                    }
                }
            },
            datasets: [{
                backgroundColor: ["#999ba8"],
                hoverBackgroundColor: ["#999ba8"],
                label: '',
                data: [1],
                fill: false,
            }
            ]
        };
    }
    $('.pieChartInv').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'pie',
            data: InvitationCountData,
            options: (invitation_count[0] < 1 && invitation_count[1] < 1 && invitation_count[2] < 1 && invitation_count[3] < 1) ? options2 : options3
        });
        $("#chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
}
export function loadAdminAnalyticClaimChart(claim_keys, claim_values, total_claim_keys, total_claim_values){
    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var index = tooltipItem.index;
                    return dataset.labels[index] + ': ' + dataset.data[index];
                }
            },
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var options2 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        innerHtml += '0';
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var ClaimCountData = {
        labels: claim_keys,
        datasets: [{
            labels: claim_keys,
            data: claim_values,
            backgroundColor: ["#444BF8", "#DD69D1", "#00ED96", "#2CD2F6", "#F12559", "#F1BE25", "#252631","#00ED96"]
        },{
            labels: total_claim_keys,
            data: total_claim_values,
            backgroundColor: ["#140A82", "#2CD2F6"]
        }
        ]
    };
    if (claim_values[0] < 1 && claim_values[1] < 1 && claim_values[2] < 1 && claim_values[3] < 1 && claim_values[4] < 1 && claim_values[5] < 1 && claim_values[6] < 1 && claim_values[7] < 1) {
        var ClaimCountData = {
            labels: [''],
            dataLabels: {
                formatter: function () {
                    if (this.y > 0) {
                        return this.point.name + ': ' + 0 + ' %'
                    }
                }
            },
            datasets: [{
                backgroundColor: ["#999ba8"],
                hoverBackgroundColor: ["#999ba8"],
                label: '',
                data: [1],
                fill: false,
            }
            ]
        };
    }
    $('.pieChartClaims').each(function (i, el) {
        new Chart(el, {
            type: 'pie',
            data: ClaimCountData,
            options: (claim_values[0] < 1 && claim_values[1] < 1 && claim_values[2] < 1 && claim_values[3] < 1 && claim_values[4] < 1 && claim_values[5] < 1 && claim_values[6] < 1 && claim_values[7] < 1) ? options2 : options1
        });

        $(el).addClass('initialized');

    });
}
export function loadAdminAnalyticDeviceChart(device_values, device_keys){
    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var options2 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title + ', ' + today.getFullYear();
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        innerHtml += '0';
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var deviceCountData = {
        labels: device_keys,
        datasets: [{
            data: device_values,
            backgroundColor: ["#444BF8", "#DD69D1"]
        }
        ]
    };
    if (device_values[0] < 1 && device_values[1] < 1 ) {
        var deviceCountData = {
            labels: [''],
            dataLabels: {
                formatter: function () {
                    if (this.y > 0) {
                        return this.point.name + ': ' + 0 + ' %'
                    }
                }
            },
            datasets: [{
                backgroundColor: ["#999ba8"],
                hoverBackgroundColor: ["#999ba8"],
                label: '',
                data: [1],
                fill: false,
            }
            ]
        };
    }

    $('.pieChartDevice').each(function (i, el) {
        new Chart(el, {
            type: 'doughnut',
            data: deviceCountData,
            options: (device_values[0] < 1 && device_values[1] < 1) ? options2 : options1
        });

        $(el).addClass('initialized');

    });
}
export function loadAdminAnalyticUserPageChart(date_array, users_count, page_count){
    var options = {
            responsive: true,
            maintainAspectRatio: false,
            // legendCallback: function(chart) {
            //     var text = [];
            //     var legs = [];
            //     for( var j=0; j<chart.data.datasets.length;j++)
            //     {
            //         for (var i = 0; i < chart.data.datasets[j].data.length; i++)
            //         {
            //             console.log(chart.data.datasets[j])
            //             var newd = { label: chart.data.datasets[j].label , color: chart.data.datasets[j].borderColor  };
            //
            //             if( !containsObject (newd,legs) )
            //             {
            //                 legs.push(newd);
            //             }
            //         }
            //     }
            //
            //     text.push('<div class=" row ' + chart.id + '-legend">');
            //     for( var k =0;k<legs.length;k++)
            //     {
            //         text.push('<div class="col-md-6 p-0"><div class="m-1 align-items-center shadow rounded-pill p-2 pr-3">' +
            //             '<span class="rounded-pill px-4 mr-2" style="background-color:' + legs[k].color + '"></span>');
            //         text.push(legs[k].label);
            //         text.push('</div></div>');
            //     }
            //     text.push('</div>');
            //     return text.join("");
            // },
            legend: {
                display: true
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        suggestedMin: 0,
                        callback: function(value) { return value },
                    }
                }]
            },
            hover: {
                mode: 'nearest',
                intersect: false
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjsAreaTooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjsAreaTooltip';
                        tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                        tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [],
                            bodyLines = tooltipModel.body.map(getBody),
                            today = new Date();

                        var innerHtml = '<header class="u-chartjs-tooltip-header">';

                        titleLines.forEach(function (title) {
                            innerHtml += title
                        });

                        innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                        bodyLines.forEach(function (body, i) {
                            var oldBody = body[0],

                                newBody = oldBody
                            innerHtml +=  newBody
                        });

                        innerHtml += '</div>';

                        var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                        tooltipRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    // console.log();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                },
                point: {
                    pointStyle: 'circle',
                    radius: 5,
                    hoverRadius: 7,
                    borderWidth: 3,
                    backgroundColor: '#ffffff'
                }
                // },
                // scales: {
                //     xAxes: [{
                //         gridLines: {
                //             display: false,
                //             drawBorder: false
                //         },
                //         ticks: {
                //             fontWeight: 400,
                //             fontSize: 14,
                //             fontFamily: 'Roboto, sans-serif',
                //             fontColor: '#999BA8'
                //         }
                //     }],
                //     yAxes: [{
                //         gridLines: {
                //             borderDash: [8, 8],
                //             color: '#eaf2f9',
                //             drawBorder: false,
                //             drawTicks: false,
                //             zeroLineColor: 'transparent'
                //         },
                //         ticks: {
                //             min: 0,
                //             max: 600,
                //             display: false,
                //             padding: 0
                //         }
                //     }]
            }
        },
        lineData = {
            labels: date_array,
            datasets: [{
                label: 'Users',
                data: users_count,
                backgroundColor: 'rgba(20, 10, 130, .1)',
                borderColor: 'rgba(20, 10, 130, 1)',
                type: 'line'
            },
                {
                    borderColor: '#2CD2F6',
                    label: 'Pages',
                    backgroundColor: '#2CD2F6',
                    data: page_count,

                }
            ]
        }
    $('.lineChartuser').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'bar',
            data: lineData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
}
export function loadAdminAnalyticMembershipGrowthChart(month_array, member_count, cancel_count){
    var options = {
            responsive: true,
            maintainAspectRatio: false,
            // legendCallback: function(chart) {
            //     var text = [];
            //     var legs = [];
            //     for( var j=0; j<chart.data.datasets.length;j++)
            //     {
            //         for (var i = 0; i < chart.data.datasets[j].data.length; i++)
            //         {
            //             console.log(chart.data.datasets[j])
            //             var newd = { label: chart.data.datasets[j].label , color: chart.data.datasets[j].borderColor  };
            //
            //             if( !containsObject (newd,legs) )
            //             {
            //                 legs.push(newd);
            //             }
            //         }
            //     }
            //
            //     text.push('<div class=" row ' + chart.id + '-legend">');
            //     for( var k =0;k<legs.length;k++)
            //     {
            //         text.push('<div class="col-md-6 p-0"><div class="m-1 align-items-center shadow rounded-pill p-2 pr-3">' +
            //             '<span class="rounded-pill px-4 mr-2" style="background-color:' + legs[k].color + '"></span>');
            //         text.push(legs[k].label);
            //         text.push('</div></div>');
            //     }
            //     text.push('</div>');
            //     return text.join("");
            // },
            legend: {
                display: true
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        suggestedMin: 0,
                        callback: function(value) { return value },
                    }
                }]
            },
            hover: {
                mode: 'nearest',
                intersect: false
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                intersect: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjsAreaTooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjsAreaTooltip';
                        tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                        tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [],
                            bodyLines = tooltipModel.body.map(getBody),
                            today = new Date();

                        var innerHtml = '<header class="u-chartjs-tooltip-header">';

                        titleLines.forEach(function (title) {
                            innerHtml += title
                        });

                        innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                        bodyLines.forEach(function (body, i) {
                            var oldBody = body[0],

                                newBody = oldBody
                            innerHtml +=  newBody
                        });

                        innerHtml += '</div>';

                        var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                        tooltipRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    // console.log();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                },
                point: {
                    pointStyle: 'circle',
                    radius: 5,
                    hoverRadius: 7,
                    borderWidth: 3,
                    backgroundColor: '#ffffff'
                }
                // },
                // scales: {
                //     xAxes: [{
                //         gridLines: {
                //             display: false,
                //             drawBorder: false
                //         },
                //         ticks: {
                //             fontWeight: 400,
                //             fontSize: 14,
                //             fontFamily: 'Roboto, sans-serif',
                //             fontColor: '#999BA8'
                //         }
                //     }],
                //     yAxes: [{
                //         gridLines: {
                //             borderDash: [8, 8],
                //             color: '#eaf2f9',
                //             drawBorder: false,
                //             drawTicks: false,
                //             zeroLineColor: 'transparent'
                //         },
                //         ticks: {
                //             min: 0,
                //             max: 600,
                //             display: false,
                //             padding: 0
                //         }
                //     }]
            }
        },
        barData = {
            labels: month_array,
            datasets: [{
                label: 'Memberships',
                data: member_count,
                backgroundColor: 'rgba(20, 10, 130, 1)',
            },
                {
                    borderColor: '#2CD2F6',
                    label: 'Canceled Memberships',
                    backgroundColor: '#2CD2F6',
                    data: cancel_count,

                }
            ]
        }
    $('.pie_membership_growth').each(function (i, el) {
        var myChart = new Chart(el, {
            type: 'bar',
            data: barData,
            options: options
        });
        // $("#user-page-chartjs-legend").html(myChart.generateLegend());
        $(el).addClass('initialized');

    });
}
export function loadAdminAnalyticVisitorChart(date_array, visitor_count){
    var options1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    callback: function(value) { return value },
                }
            }]
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    try {
                        let label = ' ' + data.labels[tooltipItem.index] || '';

                        if (label) {
                            label += ': ';
                        }

                        const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                            return parseInt(accumulator) + parseInt(curValue);
                        });
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        label += value
                        label += " ("+Number((value / sum) * 100).toFixed(2) + '%'+")";
                        return label;
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            enabled: false,
            mode: 'nearest',
            intersect: false,
            custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjsAreaTooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjsAreaTooltip';
                    tooltipEl.classList.add('u-chartjs-tooltip-wrap');
                    tooltipEl.innerHTML = '<div class="u-chartjs-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [],
                        bodyLines = tooltipModel.body.map(getBody),
                        today = new Date();

                    var innerHtml = '<header class="u-chartjs-tooltip-header">';

                    titleLines.forEach(function (title) {
                        innerHtml += title
                    });

                    innerHtml += '</header><div class="u-chartjs-tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                        var oldBody = body[0],
                            newBody = oldBody

                        innerHtml += newBody
                    });

                    innerHtml += '</div>';

                    var tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                // console.log();

                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: {
            duration: 500,
            easing: "easeOutQuart",
            onComplete: function () {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {

                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                            total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                            mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                            start_angle = model.startAngle,
                            end_angle = model.endAngle,
                            mid_angle = start_angle + (end_angle - start_angle)/2;

                        var x = mid_radius * Math.cos(mid_angle);
                        var y = mid_radius * Math.sin(mid_angle);

                        ctx.fillStyle = '#fff';
                        if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                        }
                        var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                        // ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                        // Display percent in another line, line break doesn't work for fillText
                        // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    }
                });
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                pointStyle: 'circle',
                radius: 5,
                hoverRadius: 7,
                borderWidth: 3,
                backgroundColor: '#ffffff'
            }
        }
    };
    var visitorCountData = {
        labels: ['UNIQUE VISITORS', 'REPEAT VISITORS'],
        datasets: [{
            backgroundColor: ["#140A82", "#12AFE2"],
            hoverBackgroundColor: ["#140A82", "#12AFE2"],
            label: 'Visitor',
            data: visitor_count,
            fill: false,
        }
        ]
    };

    $('.pieChartVisitor').each(function (i, el) {
        new Chart(el, {
            type: 'doughnut',
            data: visitorCountData,
            options: options1
        });
        $(el).addClass('initialized');
    });
}
export function loadHomePageChart(total_deposit_days, total_deposit_paid, paid_claim, sick_day_paid, full_emp, ass_emp, full_employer, ass_employer){
    var canvas = $(".barChartDeposit");

    var bar_ctx =canvas[0].getContext('2d');
    var purple_orange_gradient = bar_ctx.createLinearGradient(135, 0, 0, 100);
    purple_orange_gradient.addColorStop(0, '#6c26b1');
    purple_orange_gradient.addColorStop(1, '#d74566');

    var other_gradient = bar_ctx.createLinearGradient(135, 0, 0, 100);
    other_gradient.addColorStop(0, '#ae3774');
    other_gradient.addColorStop(1, '#fca892');

    var option = {
        legend: {
            display: false
        },
        segmentShowStroke : false,
        animateScale : true,
        responsive: false,
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0
                }
            }],
            xAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
    var data = {
        labels: ["Deposit Day","Deposite Amount"],
        datasets: [{
            backgroundColor: [purple_orange_gradient, purple_orange_gradient],
            hoverBackgroundColor: purple_orange_gradient,
            data: [total_deposit_days, total_deposit_paid]
        }]
    };



    var dataClaim = {
        labels: ["Claims Paid","Sick Days Paid"],
        datasets: [{
            backgroundColor: [purple_orange_gradient, purple_orange_gradient],
            hoverBackgroundColor: purple_orange_gradient,
            data: [paid_claim, sick_day_paid]
        }]
    };



    var dataEmployee = {
        labels: ["Full Members"," Associate Members", "Total Member"],
        datasets: [{
            backgroundColor: [purple_orange_gradient, purple_orange_gradient, other_gradient],
            hoverBackgroundColor: purple_orange_gradient,
            data: [full_emp, ass_emp, full_emp + ass_emp]
        }]
    };



    var dataEmployer = {
        labels: ["Full Members"," Associate Members", "Total Member"],
        datasets: [{
            backgroundColor: [purple_orange_gradient, purple_orange_gradient, other_gradient],
            hoverBackgroundColor: purple_orange_gradient,
            data: [full_employer, ass_employer, full_employer + ass_employer ]
        }]
    };

    // $(window).scroll(function() {
    //     if(isScrolledIntoView($("#Analytics"))){
            $('.barChartDeposit').each(function (i, el) {
                new Chart(el, {
                    type: 'bar',
                    data: data,
                    options: option,
                });
                $(el).addClass('initialized');
            });
            $('.barChartClaim').each(function (i, el) {
                new Chart(el, {
                    type: 'bar',
                    data: dataClaim,
                    options: option,
                });
                $(el).addClass('initialized');
            });
            $('.barChartEmployee').each(function (i, el) {
                new Chart(el, {
                    type: 'bar',
                    data: dataEmployee,
                    options: option
                });
                $(el).addClass('initialized');
            });
            $('.barChartEmployer').each(function (i, el) {
                new Chart(el, {
                    type: 'bar',
                    data: dataEmployer,
                    options: option
                });
                $(el).addClass('initialized');
            });
    //     }
    // });

}
export function loadAdminAnalyticHitChart(heat_map_user_days){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.maskBullets = false;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

    xAxis.dataFields.category = "weekday";
    yAxis.dataFields.category = "hour";

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "weekday";
    series.dataFields.categoryY = "hour";
    series.dataFields.value = "value";
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;
    series.columns.template.width = am4core.percent(100);
    series.columns.template.height = am4core.percent(100);


    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#ffffff");
    columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";

    columnTemplate.column.adapter.add("fill", function(fill, target) {
        if (target.dataItem) {
            if (target.dataItem.value == 0) {
                return am4core.color("#e4dddd")
            }
            else if (target.dataItem.value <= 15) {
                return am4core.color("#F12559")

            }
            else if (target.dataItem.value <= 30) {
                return am4core.color("#00C9A7")
            }
            else {
                return am4core.color("#2CD2F6");
            }
        }
        return fill;
    });

    var legend = new am4charts.Legend();
    legend.parent = chart.chartContainer;
    legend.data = [{
        "name": "0",
        "fill": am4core.color("#e4dddd")
    }, {
        "name": "<= 15",
        "fill": am4core.color("#F12559")
    }, {
        "name": "<= 30",
        "fill": am4core.color("#00C9A7")
    }, {
        "name": "<= 45",
        "fill": am4core.color("#2CD2F6")
    }];
    chart.data = heat_map_user_days
chart.logo.disabled = true;
}


function getRandomColor() {
    // return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    // var letters = '0123456789ABCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //     color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
}
function getRandomColorEach(count) {
    // var data =[];
    // for (var i = 0; i < count; i++) {
    //     data.push(getRandomColor());
    // }
    // return data;
    return ['#F12559', '#00C9A7', '#2CD2F6', '#444BF8', '#F1BE25', '#00ED96', '#DD69D1', '#00523E', '#FFE6FF', '#FF6F91','#2F4858','#40577A']
}
function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    if ($(elem).length) {
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
}
function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].label == obj.label ) {
            return true;
        }
    }
    return false;
}
function numberToHuman (labelValue)
{
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) < 999999
    ? (Math.abs(Number(labelValue)).toFixed(2))
            
    : Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    :( Math.abs(Number(labelValue)).toFixed(2));
}