import intlTelInput from "intl-tel-input";
import intlTelInpututility from "intl-tel-input/build/js/utils";

window.intlTelInput = intlTelInput


$(document).on('turbolinks:before-cache', function(e) {
    $("select.select2-hidden-accessible").select2('destroy');
});

document.addEventListener("turbolinks:load", function () {
    $.getScript("https://www.google.com/recaptcha/api.js");
    FilePond.registerPlugin(
        FilePondPluginFileEncode,
    );
    FilePond.parse(document.body);
    addressLookup()
    $(".bannerSlider").slick({
        autoplay: true
        , infinite: true
        , dots: true
        , slidesToShow: 1
        , slideswToScroll: 1
        , arrows: true
    });

    $(".help-desk #query").on("keyup", function(){
        if($(this).val() == ""){
            $(".results").empty();
        }else{
            searchWithAjax('/search_help_desk', $(this).val())
        }
    });
    $(".employer-faq #query").on("keyup", function(){
        if($(this).val() == ""){
            $(".results").empty();
        }else{
            searchWithAjax('/employers/faqs/search', $(this).val())
        }
    });
    $(".employee-faq #query").on("keyup", function(){
        if($(this).val() == ""){
            $(".results").empty();
        }else{
            searchWithAjax('/employees/faqs/search', $(this).val())
        }
    });

    $(".search-ajs").on("keyup", function () {
        if ($(this).val() == "") {
            $("#search-suggestion-job").empty();
        } else {
            $.ajax({
                type: "POST",
                dataType: "script",
                url: '/pages/career',
                data: {query: $(this).val()},
                success: function (data) {
                }
            });
        }
    });

    $('.second-menu a.nav-link').smoothScroll({
        duration: 1000,
        easing: 'swing',
        offset: 0
    });

    setTimeout(function () {
        $('.fix-alert').remove();
    }, 10000);
    $("body").tooltip({ selector: '[data-toggle=tooltip]' });
    $('.select2').select2();
    $('.datepicker').datepicker({
        autoclose: true,
        endDate: '1d',
        format: 'mm-dd-yyyy'
    });
    $('.dobdatepicker').datepicker({
        autoclose: true,
        endDate: '-18y',
        format: 'mm-dd-yyyy'
    });

    var sDate, eDate;
    $('.datepickerfrom').datepicker({
        autoclose: true,
        startDate: '+1d',
        format: 'mm-dd-yyyy'
    }).on('changeDate',function(e){
        sDate = new Date($(this).datepicker('getUTCDate'));
        if(sDate && eDate && (eDate<sDate)){
            $('.datepickerto').val('');
        }
        $('.datepickerto').datepicker('setStartDate', sDate);
    });


    // var sDateTime, eDateTime;
    // $('.datetimepickerfrom').datetimepicker({
    //     autoclose: true,
    //     startDate: '+1d',
    //     format: 'mm-dd-yyyy'
    // }).on('changeDate',function(e){
    //     sDateTime = new Date($(this).datetimepicker('getUTCDate'));
    //     if(sDateTime && eDateTime && (eDateTime<sDateTime)){
    //         $('.datetimedatepickerto').val('');
    //     }
    //     $('.datetimepickerto').datetimepicker('setStartDate', sDateTime);
    // });

    $('.datepickerto').datepicker({
        autoclose: true,
        startDate: '+1d',
        format: 'mm-dd-yyyy'
    }).on('changeDate',function(e) {
        eDate = new Date($(this).datepicker('getUTCDate'));
    });

    $(".img-input").change(function () {
        readPostURL(this, $(this));
    });

    $("table[role='datatable']").each(function (e) {
        $(this).on( 'draw.dt', function () {
            initConfirmationDialogs();
        } ).DataTable({
            bProcessing: true,
            bServerSide: true,
            bLengthChange: true,
            bInfo: true,
            language: {search: "", searchPlaceholder: "Search..."},
            "ordering": true,
            "order": [[0, "desc"]],
            "aoColumnDefs": [
                {'bSortable': false, 'aTargets': [-1, "no-sort"]}
            ],
            sAjaxSource: $(this).data('source')
        });
    });

    $("table[role='admin_datatable']").each(function (e) {
        $(this).on( 'draw.dt', function () {
            initConfirmationDialogs();
        } ).DataTable({
            bProcessing: true,
            bServerSide: true,
            bLengthChange: true,
            bInfo: true,
            language: {search: "", searchPlaceholder: "Search..."},
            "ordering": true,
            "order": [[0, "desc"]],
            "aoColumnDefs": [
                {'bSortable': false, 'aTargets': [-1, "no-sort"]}
            ],
            sAjaxSource: $(this).data('source')
        });
    });


    $('.invite_employer').select2({
        language: {
            noResults: function() {return "No results <a class='pull-right header-btn hidden-mobile' data-toggle='modal' data-target='#inviteEmployerModal' href='#'>Invite Employer</a>"; }
        },
        placeholder: "Select Company",
        escapeMarkup: function (markup) { return markup; }
    });




    $(".count-btn.increase").on("click", function() {
        var $button = $(this);
        var oldValue = $button.parent().find("input").val();
        var maxLimit = $button.data('max');
        var $textEle = $('#count_value');

        if (maxLimit){
            var newVal = ((parseFloat(oldValue) + 1) > parseFloat(maxLimit) ? parseFloat(maxLimit) : (parseFloat(oldValue) + 1));
        } else {
            // var newVal = parseFloat(oldValue) + 1;
            var newVal = 0;
        }
        $button.parent().find("input").val(newVal);
        $textEle.html(newVal);
    });

    $(".count-btn.decrease").on("click", function() {
        var $button = $(this);
        var oldValue = $button.parent().find("input").val();
        var $textEle = $('#count_value');
        var maxLimit = $button.data('max');

        if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
        } else {
            newVal = maxLimit ? 1 : 0;
        }
        $button.parent().find("input").val(newVal);
        $textEle.html(newVal);
    });

    initConfirmationDialogs();











    var faqAdmin = $('#faqAdmin').DataTable({
        'ajax': $('#faqAdmin').data('source'),
        'columns': [
            {
                'className':      'details-control',
                'orderable':      false,
                'data':           null,
                'defaultContent': ''
            },
            { 'data': 'sid' },
            { 'data': 'que' },
            { 'data': 'action' }
        ],
        'order': [[1, 'asc']]
    });

    if ($('#invitationDatatable')){
        var invitationDatatable = $('#invitationDatatable').DataTable({
            bProcessing: true,
            bServerSide: true,
            bLengthChange: true,
            bInfo: true,
            language: {search: "", searchPlaceholder: "Search..."},
            "ordering": true,
            "order": [[0, "desc"]],
            "aoColumnDefs": [
                {'bSortable': false, 'aTargets': [-1, "no-sort"]}
            ],
            sAjaxSource: $('#invitationDatatable').data('source')
        });
        if ($('#invitationDatatable').data('refresh') == true) {
            setInterval(function () {
                invitationDatatable.ajax.reload(null, false); // user paging is not reset on reload
            }, 10000);
        }
    }



    // Add event listener for opening and closing details
    $('#faqAdmin tbody').on('click', 'td.details-control', function(){
        var tr = $(this).closest('tr');
        var row = faqAdmin.row( tr );

        if(row.child.isShown()){
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
        } else {
            // Open this row
            row.child(format(row.data())).show();
            tr.addClass('shown');
        }
    });

    $(".renewal-flag input[type='radio']").on('click', function () {
        $form = $(this).closest('form')
        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: $form.serializeArray(),
            dataType: "JSON",
            success: function(result) {}

        });
    })

    // var myDate = new Date("30 September 2020 8:00 PST");
    var myDate = new Date($("#countdown").data('date'));
    myDate.setDate(myDate.getDate() );
    $("#countdown").countdown(myDate, function (event) {
        $(this).html(
            event.strftime(
                '<div class="timer-wrapper"><div class="time">%D</div><span class="text">days</span></div><div class="timer-wrapper"><div class="time">%H</div><span class="text">hrs</span></div><div class="timer-wrapper"><div class="time">%M</div><span class="text">mins</span></div> <div class="timer-wrapper"><div class="time">%S</div><span class="text">Sec</span></div> '
            )
        );
    });

    $('.select_all_notification_btn').click(function(){
        if($(this).prop("checked")) {
            $(this).closest("div.chk-group").find('.custom-control-input').prop("checked", true);
        } else {
            $(this).closest('div.chk-group').find('.custom-control-input').prop("checked", false);
        }
    });

    // Phone number Format

    $(document).on('.phone-number-formate',"keydown", function(e){
        enforceFormat(e)
    });
    $(document).on('.phone-number-formate', "keyup", function(e){
        formatToPhone(e)
    });


    // Password Validation
    $("input#user_password, input#admin_password").on("focus keyup", function () {
        var score = 0;
        var a = $(this).val();
        var desc = new Array();

        // strength desc
        desc[0] = "Too short";
        desc[1] = "Weak";
        desc[2] = "Good";
        desc[3] = "Strong";
        desc[4] = "Best";

    });

    $("input#user_password, input#admin_password").on("focus keyup", function () {
        var score = 0;
        var a = $(this).val();
        var desc = new Array();

        // strength desc
        desc[0] = "Too short";
        desc[1] = "Weak";
        desc[2] = "Good";
        desc[3] = "Strong";
        desc[4] = "Best";

        // password length
        if (a.length >= 8) {
            $("#length").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#length").removeClass("valid").addClass("invalid");
        }

        // at least 1 digit in password
        if (a.match(/\d/)) {
            $("#pnum").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#pnum").removeClass("valid").addClass("invalid");
        }

        // at least 1 capital letter in password
        if (a.match(/[A-Z]/)) {
            $("#capital").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#capital").removeClass("valid").addClass("invalid");
        }
        // at least 1 lower letter in password
        if ( a.match(/[a-z]/)) {
            $("#small").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#small").removeClass("valid").addClass("invalid");
        }

        // at least 1 special character in password {
        if ( a.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ) {
            $("#spchar").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#spchar").removeClass("valid").addClass("invalid");
        }


        if(a.length > 0) {
            //show strength text
            $("#passwordDescription").text(desc[score]);
            // show indicator
            $("#passwordStrength").removeClass().addClass("strength"+score);
        } else {
            $("#passwordDescription").text("Password not entered");
            $("#passwordStrength").removeClass().addClass("strength"+score);
        }
    });

    $("input#user_password, input#admin_password").blur(function () {

    });
    $("input#user_password, input#admin_password").on("focus keyup", function () {
        var score = 0;
        var a = $(this).val();
        var desc = new Array();

        // strength desc
        desc[0] = "Too short";
        desc[1] = "Weak";
        desc[2] = "Weak";
        desc[3] = "Good";
        desc[4] = "Strong";
        desc[5] = "Best";

        $("#pwd_strength_wrap").fadeIn(400);

        // password length
        if (a.length >= 8) {
            $("#length").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#length").removeClass("valid").addClass("invalid");
        }

        // at least 1 digit in password
        if (a.match(/\d/)) {
            $("#pnum").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#pnum").removeClass("valid").addClass("invalid");
        }

        // at least 1 capital letter in password
        if (a.match(/[A-Z]/) ) {
            $("#capital").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#capital").removeClass("valid").addClass("invalid");
        }

        // at least 1 lower letter in password
        if ( a.match(/[a-z]/)) {
            $("#small").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#small").removeClass("valid").addClass("invalid");
        }

        // at least 1 special character in password {
        if ( a.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ) {
            $("#spchar").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#spchar").removeClass("valid").addClass("invalid");
        }


        if(a.length > 0) {
            //show strength text
            $("#passwordDescription").text(desc[score]);
            // show indicator
            $("#passwordStrength").removeClass().addClass("strength"+score);
        } else {
            $("#passwordDescription").text("Password not entered");
            $("#passwordStrength").removeClass().addClass("strength"+score);
        }
    });

    $("input#user_password, input#admin_password").blur(function () {
        $("#pwd_strength_wrap").fadeOut(400);
    });

    $(".toggle-password-in").on("focus", function () {
        $(this).siblings('.toggle-password').removeClass("show hide");
        $(this).attr("type", "text");
        $(this).siblings('.toggle-password').addClass("hide");
    });
    $(".toggle-password-in").on("focusout", function () {
        $(this).siblings('.toggle-password').removeClass("show hide");
        $(this).attr("type", "password");
        $(this).siblings('.toggle-password').addClass("show");
    });
    $(".toggle-password").click(function () {
        $(this).toggleClass("show hide");
        var input = $($(this).data("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    initPhoneCountryCode();
    imageCropper();

});

$(document).on("change", ".chk-submit", function(e){
    $form = $(this).closest('form')
    $.ajax({
        type: 'PATCH',
        url: $form.attr('action'),
        data: $form.serializeArray(),
        dataType: "script",
        success: function(result) {}

    });
});



function isNumericInput(event){
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
};

function isModifierKey(event){
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
};

export function enforceFormat(event){
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
    }
};

export function formatToPhone(event){
    if(isModifierKey(event)) {return;}

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
    else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
    else if(input.length > 0){target.value = `(${zip}`;}
};



$(document).on("change", "input.file-uploader", function(e){
    var fileName = e.target.files[0].name;
    $(".file-uploader-name").html(fileName)
});

function format ( d ) {
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Answer:</td>'+
        '<td>'+d.ans+'</td>'+
        '</tr>'+
        '</table>';
}


function initConfirmationDialogs() {
    $("a.confirmation-message-dialog").confirm({
        title: $(this).data('title'),
        content: $(this).data('content'),
        type: 'red',
        typeAnimated: true,
        buttons: {
            close: function () {}
        }
    });

    $("a.confirmation-dialog").confirm({
        title: $(this).data('title'),
        content: $(this).data('content'),
        type: 'red',
        typeAnimated: true,
        buttons: {
            "Yes! Sure": function(){
                location.href = this.$target.attr('href');
            },
            close: function () {}
        }
    });
}

function readPostURL(input, currObj) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            currObj.closest('.upload-image-preview').find('.img-input-tag').attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
}

(function($){
    'use strict';
    $.fn.smoothScroll = function(setting) {
        var _default = {
                duration: 1000,
                easing: 'swing',
                offset: 0
            },
            _setting = $.extend(_default, setting),
            _handler = function() {
                var dest = 0,
                    target = this.hash,
                    $target = $(target);

                $(this).on('click', function(e){
                    e.preventDefault();
                    if ($target.offset().top > ($(document).height() - $(window).height())) {
                        dest = $(document).height() - $(window).height();
                    } else {
                        dest = $target.offset().top - _setting.offset;
                    }
                    $('html, body').animate({
                        scrollTop: dest
                    }, _setting.duration, _setting.easing);
                });
            };
        return this.each(_handler);
    };

})(jQuery);

$(function () {

    var $video = $('#video');
    var $window = $(window);
    if($video.length > 0) {
        $window.scroll(function () {

            var $topOfVideo = $video.offset().top;
            var $bottomOfVideo = $video.offset().top + $video.outerHeight();
            var $topOfScreen = $window.scrollTop();
            var $bottomOfScreen = $window.scrollTop() + $window.innerHeight();

            if (($bottomOfScreen > $bottomOfVideo) && ($topOfScreen < $bottomOfVideo)) {
                $video[0].play();
            } else {
                $video[0].pause();
            }

        });
    }

});


function imageCropper() {
    var $uploadCrop,
        tempFilename,
        rawImg,
        imageId;
    function readFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('.upload-demo').addClass('ready');
                $('#cropImagePop').modal('show');
                rawImg = e.target.result;
            }
            reader.readAsDataURL(input.files[0]);
        }
        else {
            swal("Sorry - you're browser doesn't support the FileReader API");
        }
    }

    $uploadCrop = $('#upload-demo').croppie({
        viewport: {
            width: 150,
            height: 200,
        },
        enforceBoundary: false,
        enableExif: true
    });
    $('#cropImagePop').on('shown.bs.modal', function(){
        $uploadCrop.croppie('bind', {
            url: rawImg
        }).then(function(){
            console.log('jQuery bind complete');
        });
    });

    $('.img-input').on('change', function () { imageId = $(this).data('id'); tempFilename = $(this).val();
        $('#cancelCropBtn').data('id', imageId); readFile(this); });
    $('#cropImageBtn').on('click', function (ev) {
        $uploadCrop.croppie('result', {
            type: 'base64',
            format: 'jpeg',
            size: {width: 150, height: 200}
        }).then(function (resp) {
            $('#image_text').val(resp)
            $('.img-input-tag').attr('src', resp);
            $('#cropImagePop').modal('hide');
        });
    });
}
export function addressLookup() {
    $('.user-location').each(function () {
        var options = {
            componentRestrictions: {country: "us"}
        };
        var places = new google.maps.places.Autocomplete(this, options);
        var cityclass =($(this).data('cityclass'));
        var stateclass =($(this).data('stateclass'));
        var zipclass =($(this).data('zipclass'));

        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            var rquiredAddress = place.formatted_address.slice(0, place.formatted_address.lastIndexOf(","));
            for (var i = 0; i < place.address_components.length; i++) {

                var addressType = place.address_components[i].types[0];

                if (addressType  == "locality") {
                    var city = place.address_components[i]['long_name'];
                }else if (addressType  == "administrative_area_level_1") {
                    var state = place.address_components[i]['long_name'];
                }else if (addressType  == "postal_code") {
                    var postalcode = place.address_components[i]['long_name'];
                }
            }
            if (cityclass && stateclass){
                $('.'+cityclass).val(city);
                $('.'+stateclass).val(state);
            }
            if (zipclass){
                $('.'+zipclass).val(postalcode);
            }
        });

        google.maps.event.addDomListener(this, 'keydown', function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    });
}

function searchWithAjax(url, query) {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: url,
        data: { query: query },
        success: function(data){}
    });
}

export function initPhoneCountryCode() {
    var ary = Array.prototype.slice.call(document.querySelectorAll(".phone-number-formate"));

    ary.forEach(function(el) {
        PhoneDisplay(el);
    });
}

function PhoneDisplay(input) {
// Set Phone Number Country Code Validations
    var errorMsg = document.querySelector(".error-msg")

    if(input){
        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

        // initialise plugin
        var iti = window.intlTelInput(input, {
            allowExtensions: true,
            formatOnDisplay: false,
            autoFormat: false,
            autoHideDialCode: true,
            autoPlaceholder: true,
            initialCountry: "us",
            nationalMode: false,
            numberType: "MOBILE",
            preventInvalidNumbers: true,
            separateDialCode: true,
            utilsScript: intlTelInpututility
        });
        var reset = function() {
            input.classList.remove("error");
            input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = ""
            // errorMsg.innerHTML = "";
            // errorMsg.classList.add("d-none");
            // validMsg.classList.add("d-none");
        };



        // on blur: validate
        input.addEventListener('blur', function() {
            reset();
            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    // validMsg.classList.remove("d-none");
                    // iso2 country code
                    var getCode = iti.getSelectedCountryData().dialCode
                    input.closest('.form-group').getElementsByClassName('phone-hidden')[0].value = "+"+getCode.toString() + input.value.trim()
                    // $('#user_contact_phone').val("+"+getCode.toString() + input.value.trim())
                    $('.phone-number-formate').closest("form").find('input[type="submit"]').attr("disabled", false);
                } else {
                    input.classList.add("error");
                    var errorCode = iti.getValidationError();
                    // errorMsg.innerHTML = errorMap[errorCode];
                    // input.closest(".form-group").find(".error-msg").html(errorMap[errorCode])
                    input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = errorMap[errorCode];
                    $('.phone-number-formate').closest("form").find('input[type="submit"]').attr("disabled", true);
                }
            }
        });

        input.addEventListener("countrychange", function(e, countryData) {
        });
        // on keyup / change flag: reset
        input.addEventListener('change', reset);
        input.addEventListener('keyup', reset);
    }
}


