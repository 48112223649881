document.addEventListener("turbolinks:load", function () {

});

$(document).on("change", "#employee_is_active", function(){
    form = $(this).closest('form')[0];
    Rails.fire(form, 'submit');
});

$(document).on("click", "#payroll-label-btn", function(){
    $("#payroll-label").addClass('d-none').removeClass('d-block');
    $("#payroll-edit").removeClass('d-none').addClass('d-block');
});