// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs"); //.start()
import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
require("bootstrap")
require("bootstrap-datepicker")
require("jquery-confirm")
require('lodash');
require("moment")
import moment from 'moment'
window.moment = moment

require("chart.js")
require("wowjs")
require("intl-tel-input")
require("filepond")
require("../custome/jquery-treetable")

require("../lib/jquery.validate")
require("../lib/jv-additional-methods.min")
require("../lib/sidebar-nav")

require("../lib/chart.min")
require("../lib/chartjs-plugin-style.min")
require("../lib/area-chart")
require("../lib/area-chart-small")
require("../lib/doughnut-chart")

require("../lib/owl.carousel.min")
require("../lib/jquery.easing.min")
require("../lib/scrolling-nav")
require("../lib/countdown-timer.min")


require("../custome/slick.min")
require("../custome/croppie")
require("../custome/custome")
require("../custome/payment_handler")
require("../custome/employees/stripe_account_form")
require("../custome/employees/payment_handler")

require("../custome/employees/custome")
require("../custome/employers/custome")
require("../custome/admins/custome")
require("../custome/infinity_scroll")
require("../custome/daterangepicker")

require("../custome/employees/select_autocomplete")

var dt = require( 'datatables.net-bs4' )

import 'select2'
import 'bootstrap-datepicker'

import $ from 'jquery';
import * as FilePond from 'filepond';
window.FilePond = FilePond;
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
window.FilePondPluginFileEncode = FilePondPluginFileEncode;

const pond = FilePond.create({
    multiple: true,
    name: 'filepond'
});


const WOW = require('wowjs');
import intlTelInput from "intl-tel-input";
window.wow = new WOW.WOW({
    live: false
});

import * as intlTelInpututility from "intl-tel-input/build/js/utils";

window.intlTelInput = intlTelInput
window.intlTelInpututility = intlTelInpututility
import * as am4core from "@amcharts/amcharts4/core";
window.am4core = am4core
import * as am4charts from "@amcharts/amcharts4/charts";
window.am4charts = am4charts
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
window.am4themes_animated = am4themes_animated



global.$ = jQuery;



import initialize_signup_form from '../custome/kassi'
import initialize_stripe_account_form from '../custome/employees/stripe_account_form'
import initialize_stripe_account from '../custome/employees/payment_handler'
import initialize_defaults from '../custome/kassi'
import set_employee_select from '../custome/employees/select_autocomplete'

import {loadMembershipLineChart, loadEmpPaidLineChart, loadCmpPaidLineChart, loadAdminAccountChart,
    loadAdminAnalyticChart, loadAdminHelpChart, loadAdminHelpAvgChart, loadAdminHelpAvgCharts,
    loadAdminHelpTicketAnalyticCharts, loadAdminHelpCBRAnalyticCharts, loadAdminHelpEQAnalyticCharts,
    loadHomePageChart, loadAdminAnalyticClaimChart, loadAdminAnalyticUserPageChart,
    loadAdminAnalyticEmpInvChart, loadAdminAnalyticVisitorChart, loadAdminAnalyticHitChart,
    loadAdminAnalyticCompMemberChart, loadAdminAnalyticEmpMemberChart, loadAdminAnalyticDeviceChart,
    loadAdminAnalyticMembershipGrowthChart} from '../lib/area-chart-small'
import {enforceFormat, formatToPhone, initPhoneCountryCode, addressLookup} from '../custome/custome'


import '../stylesheets/application.scss'

window.initialize_signup_form = initialize_signup_form;
window.initialize_stripe_account_form = initialize_stripe_account_form;
window.initialize_stripe_account = initialize_stripe_account;
window.initialize_defaults = initialize_defaults;
window.loadMembershipLineChart = loadMembershipLineChart;
window.loadEmpPaidLineChart = loadEmpPaidLineChart;
window.loadCmpPaidLineChart = loadCmpPaidLineChart;
window.loadAdminAccountChart = loadAdminAccountChart;
window.set_employee_select = set_employee_select;
window.loadAdminAnalyticChart = loadAdminAnalyticChart;
window.loadAdminAnalyticClaimChart = loadAdminAnalyticClaimChart;
window.loadAdminAnalyticUserPageChart = loadAdminAnalyticUserPageChart;
window.loadAdminAnalyticVisitorChart = loadAdminAnalyticVisitorChart;
window.loadAdminAnalyticHitChart = loadAdminAnalyticHitChart;
window.loadAdminAnalyticEmpInvChart = loadAdminAnalyticEmpInvChart;
window.loadAdminAnalyticCompMemberChart = loadAdminAnalyticCompMemberChart;
window.loadAdminAnalyticEmpMemberChart = loadAdminAnalyticEmpMemberChart;
window.loadAdminAnalyticDeviceChart = loadAdminAnalyticDeviceChart;
window.loadAdminAnalyticMembershipGrowthChart = loadAdminAnalyticMembershipGrowthChart;

window.loadAdminHelpChart = loadAdminHelpChart;
window.loadAdminHelpAvgChart = loadAdminHelpAvgChart;
window.loadAdminHelpAvgCharts = loadAdminHelpAvgCharts;
window.loadAdminHelpTicketAnalyticCharts = loadAdminHelpTicketAnalyticCharts;
window.loadAdminHelpCBRAnalyticCharts = loadAdminHelpCBRAnalyticCharts;
window.loadAdminHelpEQAnalyticCharts = loadAdminHelpEQAnalyticCharts;

window.loadHomePageChart = loadHomePageChart;
window.enforceFormat = enforceFormat;
window.formatToPhone = formatToPhone;
window.initPhoneCountryCode = initPhoneCountryCode;
window.addressLookup = addressLookup;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//banner slider
