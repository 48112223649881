function initialize_stripe_account_form(ele_id){
    var form_id = "#"+ele_id;
    $(".Employer, .Employee").hide();
    $("."+$("input[name='user[type]']:checked").val()).show();
    $('.user_type').on("change", function(){
        $(".Employer, .Employee").hide();
        $("."+$(this).val()).show();
    });
    $(form_id).validate({
        ignore: ":hidden",
        errorPlacement: function(errorLabel, element) {
            if (( /radio|checkbox/i ).test( element[0].type )) {
                element.closest('.checkbox-container').append(errorLabel);
            } else {
                errorLabel.insertAfter( element );
            }
        },
        rules: {
            "stripe_form[first_name]": {required: true},
            "stripe_form[last_name]": {required: true},
            "stripe_form[dob]": {required: true},
            "stripe_form[country]": {required: true},
            "stripe_form[address_line_1]": {required: true},
            "stripe_form[postal_code]": {required: true, number: true, minlength: 5, maxlength: 5},
            "stripe_form[city]": {required: true},
            "stripe_form[state]": {required: true},
            "stripe_form[phone]": {required: true},
            "stripe_form[account_ssn]": {required: true, number: true, minlength: 4, maxlength: 4},
            "stripe_form[routing_number]": {required: true, number: true, maxlength: 12},
            "stripe_form[account_number]": {required: true, number: true, maxlength: 20},
        },
        messages: {},
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function(form) {
        }
    });
}

export default initialize_stripe_account_form;